<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card-text
    class="pb-0 mt-2"
    :style="cssProps"
  >
    <v-tabs
      v-if="hasExtendedOption"
      v-model="tab"
    >
      <v-tab>
        {{ $t('landingpages.snippet.tabs.compact.title') }}
      </v-tab>
      <v-tab>
        {{ $t('landingpages.snippet.tabs.extended.title') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-if="hasExtendedOption"
      v-model="tab"
      class="mt-2 mb-6"
    >
      <v-tab-item>
        <span>{{ $t('landingpages.snippet.tabs.compact.text') }}.</span>
      </v-tab-item>

      <v-tab-item>
        <span>{{ $t('landingpages.snippet.tabs.extended.text') }}</span>
      </v-tab-item>
    </v-tabs-items>

    <v-row
      class="mb-2"
      :class="{'mt-2': !hasExtendedOption}"
    >
      <v-col
        cols="12"
        md="6"
      >
        <h3 class="mb-4">
          {{ $t('landingpages.snippet.code', { textCodeTitle }) }}:
        </h3>
        <div>
          <CodeSnippet :snippet="snippet" />

          <div class="mt-6">
            <p
              v-if="landingpageType === 'RETURN_CALL'"
              v-html="$t('landingpages.snippet.text-return-call')"
            />
            <p
              v-html="$t('landingpages.snippet.text-other', { readablePrefix, readableLandingpage })"
            />

            <p class="mt-4">
              {{ $t('landingpages.snippet.link-texts.0') }} <a
                :href="helpLinks.iFrameImplementation"
                target="_blank"
                rel="noopener noreferrer"
              >{{ $t('landingpages.snippet.link-texts.1') }}</a>.
            </p>
          </div>
        </div>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="12"
        md="6"
      >
        <h3 class="mb-4">
          {{ $t('labels.preview') }}:
        </h3>

        <v-tabs-items
          v-model="tab"
          class="mt-2"
        >
          <v-tab-item>
            <div class="preview grey lighten-4">
              <div class="wrapper d-flex justify-center">
                <iframe
                  class="scaled-frame"
                  :src="`${companySiteUrl}/${landingpageSlug}/embed`"
                />
              </div>
            </div>
          </v-tab-item>

          <v-tab-item v-if="hasExtendedOption">
            <div class="preview d-flex justify-center grey lighten-4">
              <div class="wrapper">
                <iframe
                  class="scaled-frame"
                  :src="`${companySiteUrl}/${landingpageSlug}/embed?variant=extended`"
                />
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import IFrameCssMap from './IFrameCssMap'
import helpLinks from '@/lib/helpLinks'
import CodeSnippet from './CodeSnippet.vue'

export default {
  components: {
    CodeSnippet
  },
  props: {
    companySlug: { type: String, default: null },
    companySiteUrl: { type: String, default: '' },
    landingpageSlug: { type: String, default: null },
    readablePrefix: { type: String, default: null },
    readableLandingpage: { type: String, default: null },
    landingpageType: { type: String, default: '' },
    small: { type: Boolean, default: false }
  },
  data () {
    return {
      tab: 0,
      helpLinks
    }
  },
  computed: {
    snippet () {
      return `
      <script
      src="${process.env.VUE_APP_IFRAME_LOADER_URL}"
      data-company="${this.companySlug}"
      data-slug="${this.landingpageSlug}"
      ${['CHECKLIST', 'GUIDE'].includes(this.landingpageType) && this.tab === 1 ? 'data-variant="extended"' : ''}
      data-bottimmo defer>
      <\/script>`.replace(/\n/g, ' ') // eslint-disable-line no-useless-escape
    },
    hasExtendedOption () {
      return ['CHECKLIST', 'GUIDE'].includes(this.landingpageType)
    },
    cssProps () {
      const variant = this.tab === 1 ? 'extended' : 'basic'
      return {
        '--wrapper-width': IFrameCssMap[this.landingpageType][variant].wrapperWidth,
        '--iframe-zoom': IFrameCssMap[this.landingpageType][variant].zoom,
        '--iframe-width': IFrameCssMap[this.landingpageType][variant].width,
        '--iframe-height': IFrameCssMap[this.landingpageType][variant].height,
        '--iframe-margin-top': IFrameCssMap[this.landingpageType][variant].marginTop || 0,
        '--iframe-margin-left': IFrameCssMap[this.landingpageType][variant].marginLeft || 0
      }
    },
    textCodeTitle () {
      return this.hasExtendedOption ? this.tab === 0 ? this.$t('landingpages.snippet.options.0') : this.$t('landingpages.snippet.options.1') : ''
    }
  }
}
</script>

<style scoped>
.preview{
  height: 400px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.wrapper {
  width: var(--wrapper-width);
  height: 100%;
  padding: 0;
  overflow: hidden;
}

.scaled-frame {
  margin-top: var(--iframe-margin-top);
  margin-left: var(--iframe-margin-left);
  width: var(--iframe-width);
  height: var(--iframe-height);
  border: 0px;
  zoom: var(--iframe-zoom);
  -moz-transform: scale(var(--iframe-zoom));
  -moz-transform-origin: center center;
  -o-transform: scale(var(--iframe-zoom));
  -o-transform-origin:  center center;
  -webkit-transform: scale(var(--iframe-zoom));
  -webkit-transform-origin:  center center;
  border-radius: 5px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  pointer-events: none;
  background:white;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
 .scaled-frame  { zoom: 1;  }
}

</style>
