<template>
  <div>
    <div
      style="position:relative"
      class="mb-2"
    >
      <div class="snippet rounded-lg grey lighten-4 pa-4">
        <span
          id="snippet"
          class="text-body-1"
        >
          {{ snippet }}
        </span>
      </div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="position:absolute; bottom: -20px; right: -20px"
            class="mx-2 elevation-1"
            fab
            small
            color="primary"
            v-bind="attrs"
            @click="copy"
            v-on="on"
          >
            <v-icon dark>
              mdi-content-copy
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('buttons.snippet.copy') }}</span>
      </v-tooltip>
    </div>

    <div>
      <span
        v-if="copyState==='success'"
        class="success--text"
      >
        <v-icon
          color="success"
          class="mt-n1"
        >
          mdi-check
        </v-icon>
        {{ $t('alerts.landingpages.snippet.success') }}
      </span>
      <span
        v-if="copyState==='error'"
        class="error--text"
      >
        <v-icon
          color="error"
          class="mt-n1"
        >
          mdi-close
        </v-icon>
        {{ $t('alerts.landingpages.snippet.error') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    snippet: { type: String, default: '' }
  },
  data () {
    return {
      copyState: 'idle'
    }
  },
  methods: {
    copy () {
      navigator.clipboard.writeText(this.snippet)
        .then(() => {
          this.copyState = 'success'
        })
        .catch(() => {
          this.copyState = 'error'
        })
    }
  }
}
</script>
