<template>
  <v-card
    outlined
    class="rounded-lg"
  >
    <div
      style="height: 170px"
      class="blue-grey lighten-5"
    >
      <div
        v-if="icon"
        class="pa-10"
      >
        <v-img
          :src="icon"
          height="100px"
          contain
        />
      </div>
      <v-img
        v-else
        :src="optimizedThumbnailUrl"
        class="blue-grey lighten-5"
        height="170px"
        contain
      />
    </div>

    <v-divider />

    <v-card-actions class="py-1 white">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            class="flex-grow-1 text-truncate"
            v-on="on"
            v-text="name"
          />
        </template>
        <span>{{ name }}</span>
      </v-tooltip>

      <v-btn
        icon
        download
        :href="url"
        @click="trackDownload"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import urlMixin from '@/mixins/url'

export default {
  mixins: [urlMixin],
  props: {
    name: { type: String, required: true },
    url: { type: String, required: true },
    icon: { type: String, default: '' },
    thumbnailUrl: { type: String, default: '' }
  },

  computed: {
    optimizedThumbnailUrl () {
      if (this.thumbnailUrl) {
        return this.setQueryParams(this.thumbnailUrl, { h: 340 })
      }
      return null
    }
  }
}
</script>
