<template>
  <v-dialog
    v-model="dialogOpen"
    max-width="1400"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :class="{'w-full':$vuetify.breakpoint.smAndDown}"
        color="primary"
        :small="small"
        outlined
        v-on="on"
      >
        <v-icon left>
          mdi-xml
        </v-icon>
        {{ $t('buttons.snippet.cta') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>{{ $t('buttons.snippet.cta') }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <Feature :feature-slug="featureNames.CMS">
        <template v-slot:default>
          <ComponentSnippet
            :company-slug="companySlug"
            :company-site-url="companySiteUrl"
            :landingpage-slug="landingpageSlug"
            :readable-prefix="readablePrefix"
            :readable-landingpage="readableLandingpage"
            :landingpage-type="landingpageType"
          />
        </template>

        <template v-slot:inactive>
          <IFrameSnippet
            :company-slug="companySlug"
            :company-site-url="companySiteUrl"
            :landingpage-slug="landingpageSlug"
            :readable-prefix="readablePrefix"
            :readable-landingpage="readableLandingpage"
            :landingpage-type="landingpageType"
          />
        </template>
      </Feature>

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="close"
        >
          {{ $t('labels.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import feature from '@/mixins/feature'
import ComponentSnippet from './ComponentSnippet.vue'
import IFrameSnippet from './IFrameSnippet.vue'

export default {
  components: {
    ComponentSnippet,
    IFrameSnippet
  },
  mixins: [feature],
  props: {
    companySlug: { type: String, default: null },
    companySiteUrl: { type: String, default: '' },
    landingpageSlug: { type: String, default: null },
    readablePrefix: { type: String, default: null },
    readableLandingpage: { type: String, default: null },
    landingpageType: { type: String, default: '' },
    small: { type: Boolean, default: false }
  },
  data () {
    return {
      dialogOpen: false
    }
  },
  methods: {
    close () {
      this.dialogOpen = false
    }
  }
}
</script>
